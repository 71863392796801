<template>
  <div>
    <div class="mb-4">
      <div class="text-h6 font-weight-medium third--text">
        {{ $t("frauds.title") }}
      </div>
    </div>
    <v-card class="rounded-md mb-4" dark color="fifth">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn
          depressed
          class="mr-2"
          @click="getFilters()"
          :loading="loading.filter"
        >
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <v-btn depressed @click="exportFrauds()" :loading="loading.export">
          <v-icon left>mdi-file-excel-outline</v-icon>
          {{ $t("btn.export") }}
        </v-btn>
      </div>
    </v-card>
    <v-card dark color="fifth">
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="third--text">{{ $t("frauds.list.title") }}</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn outlined color="third" v-if="meta.total">{{
          $tc("counter.fraud", meta.total, {
            count: $utils.pad(meta.total, 2),
          })
        }}</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :server-items-length="meta.total"
        sort-by="id"
        sort-desc
        dark
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px">
                    <span>{{
                      $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td>{{ item.client.identifier }}</td>
                  <td>
                    <v-chip
                      class="table-chip"
                      v-if="item.type === 'CHECK_PASSWORD'"
                      color="third"
                      ><span class="black--text font-weight-bold">{{
                        $t("frauds.fields.operation.CHECK_PASSWORD")
                      }}</span></v-chip
                    >
                    <v-chip
                      class="table-chip"
                      color="third"
                      v-else-if="item.type === 'CHECK_PROMO_CODE'"
                      ><span class="black--text font-weight-bold">{{
                        $t("frauds.fields.operation.CHECK_PROMO_CODE")
                      }}</span></v-chip
                    >
                  </td>
                  <td>
                    {{ item.content }}
                  </td>
                  <td>{{ item.reseller.name }}</td>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="getDetails(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                        >
                          <v-icon color="third">mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.details") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <fraud-filter
      @applyFilter="filterItems"
      :dialog="dialog.filter"
    ></fraud-filter>
    <fraud-detail :fraud="fraudSelected" :dialog="dialog.detail"></fraud-detail>
    <fraud-export
      :dialog="dialog.export"
      @exportConfirm="exportConfirmed"
    ></fraud-export>
  </div>
</template>

<script>
import FraudFilter from "./filter";
import FraudDetail from "./details";
import FraudExport from "./export";
import ListMixin from "./../../mixins/commons/list";
import { mapActions } from "vuex";

export default {
  mixins: [ListMixin],
  data: () => ({
    path: "/api/v1/frauds",
    dialog: {
      filter: {
        display: false,
      },
      detail: {
        display: false,
      },
      export: {
        display: false,
      },
    },
    fraudSelected: {},
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("frauds.fields.created.title"),
          align: "start",
          sortable: true,
          value: "created",
          width: 160,
        },
        {
          text: this.$t("frauds.fields.clientId.title"),
          align: "start",
          sortable: true,
          value: "client.identifier",
          width: 110,
        },
        {
          text: this.$t("frauds.fields.operation.title"),
          align: "start",
          sortable: true,
          value: "type",
          width: 110,
        },
        {
          text: this.$t("frauds.fields.content.title"),
          align: "start",
          sortable: false,
          value: "content",
          width: 110,
        },
        {
          text: this.$t("frauds.fields.resellerName.title"),
          align: "start",
          sortable: false,
          value: "reseller.name",
        },
        {
          text: "",
          align: "start",
          value: null,
          sortable: false,
          width: 80,
        },
      ];
    },
    getFilters() {
      this.dialog.filter.display = true;
    },
    getDetails(item) {
      this.fraudSelected = item;
      this.dialog.detail.display = true;
    },
    async exportConfirmed(daterange) {
      if (!daterange) {
        this.notify({ message: this.$t("frauds.errors.daterange_is_empty") });
        return;
      }
      this.loading.export = true;
      try {
        const startDate = daterange[0] + " 00:00:00";
        const endDate = daterange[1] + " 23:59:59";

        const response = await this.request({
          url: "/api/v1/frauds/export",
          responseType: "blob",
          params: {
            created_gte: startDate,
            created_lte: endDate,
          },
        });
        const filename = this.$utils.getFilenameFromContentDisposition(
          response.headers["content-disposition"]
        );
        this.$filesaver(response.data, filename || "transactions-frauds.xlsx");
      } catch (error) {
        // empty
      }
      this.loading.export = false;
    },
    exportFrauds() {
      this.dialog.export.display = true;
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  components: {
    FraudFilter,
    FraudDetail,
    FraudExport,
  },
};
</script>