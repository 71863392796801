<template>
  <div>
    <fraud></fraud>
  </div>
</template>

<script>
import Fraud from "./../../components/frauds/list";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("frauds.title")
    };
  },
  components: {
    Fraud
  }
};
</script>