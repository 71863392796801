<template>
  <v-dialog
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark>
      <v-card-title>
        <div class="text--h6">{{ $t("frauds.detail.title") }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <div v-if="fraud.id">
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("frauds.fields.created.description")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ $moment(fraud.created).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("frauds.fields.clientId.description")
                }}</span>
              </div>
              <div class="ml-auto">{{ fraud.client.identifier }}</div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("frauds.fields.operation.description")
                }}</span>
              </div>
              <div class="ml-auto">
                <span>{{ $t("frauds.fields.operation." + fraud.type) }}</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("frauds.fields.resellerId.description")
                }}</span>
              </div>
              <div class="ml-auto">{{ fraud.reseller.identifier }}</div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.identifier.description2")
                }}</span>
              </div>
              <div class="ml-auto">{{ fraud.reseller.name }}</div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.name.description2")
                }}</span>
              </div>
              <div class="ml-auto">{{ fraud.reseller.name }}</div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.location.description2")
                }}</span>
              </div>
              <div class="ml-auto">{{ fraud.reseller.localisation }}</div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.type.description2")
                }}</span>
              </div>
              <div class="ml-auto">
                <span>{{
                  $t("resellers.fields.type." + fraud.reseller.type)
                }}</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("frauds.fields.content.title")
                }}</span>
              </div>
              <div class="ml-auto">
                <span>{{ fraud.content }}</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    fraud: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({}),
};
</script>