var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"text-h6 font-weight-medium third--text"},[_vm._v(" "+_vm._s(_vm.$t("frauds.title"))+" ")])]),_c('v-card',{staticClass:"rounded-md mb-4",attrs:{"dark":"","color":"fifth"}},[_c('div',{staticClass:"d-flex align-center justify-start px-4 py-3 flex-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","loading":_vm.loading.refresh},on:{"click":function($event){return _vm.refreshItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$t("btn.refresh"))+" ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","loading":_vm.loading.filter},on:{"click":function($event){return _vm.getFilters()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_vm._v(" "+_vm._s(_vm.$t("btn.filter"))+" ")],1),_c('v-btn',{attrs:{"depressed":"","loading":_vm.loading.export},on:{"click":function($event){return _vm.exportFrauds()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-excel-outline")]),_vm._v(" "+_vm._s(_vm.$t("btn.export"))+" ")],1)],1)]),_c('v-card',{attrs:{"dark":"","color":"fifth"}},[_c('v-card-title',[_c('div',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"third--text"},[_vm._v(_vm._s(_vm.$t("frauds.list.title")))])]),_c('v-spacer'),(_vm.meta.total)?_c('v-btn',{attrs:{"outlined":"","color":"third"}},[_vm._v(_vm._s(_vm.$tc("counter.fraud", _vm.meta.total, { count: _vm.$utils.pad(_vm.meta.total, 2), })))]):_vm._e()],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading.list,"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"footer-props":_vm.footerProps,"items-per-page":_vm.itemPerPage,"server-items-length":_vm.meta.total,"sort-by":"id","sort-desc":"","dark":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm._.isEmpty(_vm.items))?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('v-hover',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',[_c('td',{staticStyle:{"min-width":"160px"}},[_c('span',[_vm._v(_vm._s(_vm.$moment(item.created).format("YYYY-MM-DD HH:mm:ss")))])]),_c('td',[_vm._v(_vm._s(item.client.identifier))]),_c('td',[(item.type === 'CHECK_PASSWORD')?_c('v-chip',{staticClass:"table-chip",attrs:{"color":"third"}},[_c('span',{staticClass:"black--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t("frauds.fields.operation.CHECK_PASSWORD")))])]):(item.type === 'CHECK_PROMO_CODE')?_c('v-chip',{staticClass:"table-chip",attrs:{"color":"third"}},[_c('span',{staticClass:"black--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t("frauds.fields.operation.CHECK_PROMO_CODE")))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(item.content)+" ")]),_c('td',[_vm._v(_vm._s(item.reseller.name))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.getDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"third"}},[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("btn.details")))])])],1)])]}}],null,true)})]})],2)]}}:null],null,true)})],1),_c('fraud-filter',{attrs:{"dialog":_vm.dialog.filter},on:{"applyFilter":_vm.filterItems}}),_c('fraud-detail',{attrs:{"fraud":_vm.fraudSelected,"dialog":_vm.dialog.detail}}),_c('fraud-export',{attrs:{"dialog":_vm.dialog.export},on:{"exportConfirm":_vm.exportConfirmed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }