<template>
  <v-dialog
    dark
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark>
      <v-card-title>
        <div class="text--h6">{{ $t("frauds.filter.title") }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form autocomplete="off">
          <v-text-field
            :label="$t('frauds.fields.clientId.description')"
            v-model="filters.client.value"
            clearable
          ></v-text-field>
          <v-select
            :items="operations"
            :label="$t('frauds.fields.operation.description')"
            v-model="filters.type.value"
            :item-text="(item) => $t(item.text)"
          ></v-select>
          <v-autocomplete
            :label="$t('frauds.fields.resellerName.description')"
            hide-no-data
            :search-input.sync="searchReseller"
            :loading="loading.reseller"
            :items="items.resellers"
            item-text="name"
            item-value="id"
            v-model="filters.reseller.value"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="resetFilter()" color="third" text>{{
          $t("btn.reset")
        }}</v-btn>
        <v-btn @click="applyFilter()" color="secondary" text>{{
          $t("btn.filter")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

const defaultFilters = {
  client: {
    value: null,
  },
  type: {
    value: null,
  },
  reseller: {
    value: null,
  },
};

export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
  },
  data: () => ({
    filters: Vue._.cloneDeep(defaultFilters),
    items: {
      resellers: [],
    },
    searchReseller: null,
    loading: {
      reseller: false,
    },
  }),
  methods: {
    applyFilter() {
      this.$emit("applyFilter", this.filters);
      this.dialog.display = false;
    },
    resetFilter() {
      this.filters = Vue._.cloneDeep(defaultFilters);
      this.$emit("applyFilter", {});
      this.dialog.display = false;
    },
    ...mapActions({ request: "request" }),
  },
  watch: {
    async searchReseller(val) {
      if (!val) return;
      if (this.loading.reseller) return false;

      this.loading.reseller = true;
      try {
        const response = await this.request({
          url: "/api/v1/resellers",
          params: { name: val },
        });
        this.items.resellers = response.data.items;
      } catch (error) {
        // empty
      }
      this.loading.reseller = false;
    },
  },
  computed: {
    ...mapGetters({ operations: "transaction/types" }),
  },
};
</script>